import * as THREE from 'three'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import './main.css'


let camera, scene, renderer;
let plane;
let pointer, raycaster, isShiftDown = false;
let rollOverMesh, rollOverMaterial;
let cubeGeo, cubeMaterial;
const objects = [];
let loader = new GLTFLoader();
let flowerCounter = 0
//const flowers = ['../models/flower1-1.glb', '../models/flower1-2.glb', '../models/flower1-3.glb', '../models/flower1-4.glb', '../models/flower1-5.glb', '../models/flower1-6.glb', '../models/flower1-7.glb'];
const flowers = ['../models/cosmos-hot-pink.glb', '../models/cosmos-lite-pink.glb', '../models/cosmos-pink.glb', '../models/cosmos-white.glb', '../models/cosmos-yellow.glb'];

let beeSin = 0
const BeeSinSpeed = 0.1 // .1
const beewaveHeight = 3 // .5
let realRot = 0
let RotDir = false

let mixerBee; 
const clock = new THREE.Clock()
const beeDestintation = []
let beeDNum = 0; 
const beepseed = 1 //0.05
let atFlower = false
let atFlowerCounter = 0
const atFlowerTimer = 50

let clickNums = 0; 

init();
render();



function init() {

	camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000);
	// camera.position.set( 500, 800, 1300 );
	camera.position.set(0, 100, 800);
	camera.lookAt(0, 0, 0);

	scene = new THREE.Scene();
	scene.background = new THREE.Color(0xf0f0f0);

	// roll-over helpers

	const rollOverGeo = new THREE.BoxGeometry(10, 10, 10);
	rollOverMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, opacity: 0.5, transparent: true });
	rollOverMesh = new THREE.Mesh(rollOverGeo, rollOverMaterial);
	const gridHelper = new THREE.GridHelper(1200, 50);
	scene.add(gridHelper);

	const geometry2 = new THREE.PlaneGeometry(1000, 1000);


const loaderT = new THREE.TextureLoader();

loaderT.load(
	'../images/arboretum1.jpg',

	// onLoad callback
	function ( texture ) {
		// in this example we create the material when the texture is loaded
		const material2 = new THREE.MeshBasicMaterial( {
			map: texture
		 } );
		 const bgimage = new THREE.Mesh(geometry2, material2);
		 bgimage.position.x = -0;
		 bgimage.position.y = 100;
		 bgimage.position.z = -500;
		 bgimage.scale.x = 1;
		 bgimage.scale.y = 1;
		 bgimage.scale.z = 1;
		 scene.add(bgimage);

		 //animate(); 
		 render();
	},
	undefined,
	function ( err ) {
		console.error( 'An error happened.' );
	}

);
   
// loader.load('../models/flower1-1.glb', function (gltf) {
	// const loaderBee = new THREE.TextureLoader();

// loader.load('../models/bee-fast.glb', function (bee) {
// 	bee.scene.position.x = 0
// 	bee.scene.position.z = 3;
// 	bee.scene.position.y = 0;
// 	bee.scene.scale.set(20, 20, 20)
// 	scene.add(bee.scene);
// 	mixerBee = new THREE.AnimationMixer(bee.scene)
// 	bee.animations.forEach((clipBee) => {
// 	  mixerBee.clipAction(clipBee).play()
// 	})
// 	//animate(); 
// 	render();
// }, undefined, function (error) {
// 	console.error("grr");
// });

	raycaster = new THREE.Raycaster();
	pointer = new THREE.Vector2();

	const geometry = new THREE.PlaneGeometry(1200, 1200);
	geometry.rotateX(- Math.PI / 2);

	plane = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ visible: false }));
	scene.add(plane);

	objects.push(plane);

	// lights

	const ambientLight = new THREE.AmbientLight(0x6f6f6f, 1.7); // 0x606060, 1.7
	scene.add(ambientLight);

	const directionalLight = new THREE.DirectionalLight(0xffffff);
	directionalLight.position.set(1, 0.75, 0.5).normalize(); // 1, .75, .5
	scene.add(directionalLight);

	renderer = new THREE.WebGLRenderer({ antialias: true });
	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.setSize(window.innerWidth, window.innerHeight);
	document.body.appendChild(renderer.domElement);

	document.addEventListener('pointermove', onPointerMove);
	document.addEventListener('pointerdown', onPointerDown);
	document.addEventListener('keydown', onDocumentKeyDown);
	document.addEventListener('keyup', onDocumentKeyUp);

	//

	window.addEventListener('resize', onWindowResize);
	// animate(); 
}

function animate() {
	requestAnimationFrame(animate)
   const deltaBee = clock.getDelta()
   mixerBee.update(deltaBee)

   let dest = beeDestintation[beeDNum]
   let  beeForMove = scene.getObjectByName( "beeJoe" )
  

   // 

   const rot = Math.atan2(dest.z - beeForMove.position.z, dest.x - beeForMove.position.x)
   const rotSpeed = 0.2
   if (Math.abs(rot - realRot) > 0.2) {
	 if (RotDir) {
	   realRot += rotSpeed
	 } else {
	   realRot -= rotSpeed
	 }
	 if (realRot > Math.PI) {
	   realRot = -Math.PI
	 }
	 if (realRot < -Math.PI) {
	   realRot = Math.PI
	 }
   } else {
	 realRot = rot
   }
   if (atFlower == false) {
	 beeForMove.position.z += Math.sin(realRot) * beepseed
	 beeForMove.position.x += Math.cos(realRot) * beepseed
	 beeForMove.rotation.y = -realRot + Math.PI / 2

	 beeForMove.position.y = dest.y
	 
	 beeSin += BeeSinSpeed
	 beeForMove.position.y += Math.sin(beeSin) * beewaveHeight
   }

   const bee2 = new THREE.Vector2(beeForMove.position.x, beeForMove.position.z)
   const dest2 = new THREE.Vector2(dest.x, dest.z)
   const dist2 = bee2.distanceTo(dest2)
   const rotAxis = new THREE.Vector3(1, 0, 0)
   if (dist2 < 1) {  // was .2
	 if (atFlower === false) {
	   atFlower = true
	   beeForMove.rotateOnAxis( rotAxis, 0.5 );
	   //beeForMove.setRotationFromAxisAngle(rotAxis, 0.5)  // dip the bee
	 }
	 if (beeForMove.position.y > dest.y) {
	   beeForMove.position.y -= 0.1  // elevator speed
	 }
	 if (beeForMove.position.y < dest.y) {
	   beeForMove.position.y += 0.1
	 }

	 atFlowerCounter++
	 if (atFlowerCounter > atFlowerTimer) {
	   atFlower = false
	   atFlowerCounter = 0
	   const nums = []
	   for (let i = 0; i < beeDestintation.length; i++) {
		 if (i != beeDNum) {
		   nums.push(i)
		 }
	   }
	   const rannum = Math.floor(Math.random() * nums.length)
	   beeDNum = nums[rannum]
	   // console.log(beeDNum)
	//    if (beeDNum == 1) {
	// 	 const rot = Math.atan2(dest.z - camera.position.z, dest.x - camera.position.x)
	// 	 const BFront = new THREE.Vector3(camera.position.x + Math.cos(rot) * 1, camera.position.y, camera.position.z + Math.sin(rot) * 1)
	// 	 beeDestintation[1] = BFront  // add the camera to possible bee locations
	//    }
	   RotDir = !RotDir
	   beeSin = 0  // so it doesn't jump when it moves.
	   // reset dip rotation
	   beeForMove.setRotationFromAxisAngle(rotAxis, 0) // reset the dip. 
	 }

   }

   
   render(); 
}

function onWindowResize() {

	camera.aspect = window.innerWidth / window.innerHeight;
	camera.updateProjectionMatrix();

	renderer.setSize(window.innerWidth, window.innerHeight);

	render();

}

function onPointerMove(event) {

	pointer.set((event.clientX / window.innerWidth) * 2 - 1, - (event.clientY / window.innerHeight) * 2 + 1);

	raycaster.setFromCamera(pointer, camera);

	const intersects = raycaster.intersectObjects(objects, false);

	if (intersects.length > 0) {

		const intersect = intersects[0];

		rollOverMesh.position.copy(intersect.point).add(intersect.face.normal);
		rollOverMesh.position.divideScalar(50).floor().multiplyScalar(50).addScalar(25);

		render();

	}

}

function onPointerDown(event) {

	pointer.set((event.clientX / window.innerWidth) * 2 - 1, - (event.clientY / window.innerHeight) * 2 + 1);

	raycaster.setFromCamera(pointer, camera);

	const intersects = raycaster.intersectObjects(objects, false);

	if (intersects.length > 0) {

		const intersect = intersects[0];

		// delete cube

		if (isShiftDown) {

			if (intersect.object !== plane) {

				scene.remove(intersect.object);

				objects.splice(objects.indexOf(intersect.object), 1);

			}

			// create cube

		} else {

			const voxel = new THREE.Mesh(cubeGeo, cubeMaterial);
			voxel.position.copy(intersect.point).add(intersect.face.normal);
			voxel.position.divideScalar(50).floor().multiplyScalar(50).addScalar(25);

			loader.load(flowers[flowerCounter], function (gltf) {
				flowerCounter ++; 
				if (flowerCounter >= flowers.length){
				 flowerCounter = 0; 
			 }
		 
			//loader.load('../models/flower1-1.glb', function (gltf) {
				gltf.scene.position.x = intersect.point.x;
				gltf.scene.position.z = intersect.point.z;
				gltf.scene.position.y = 0;
				gltf.scene.scale.set(8, 8, 8)
				let ranRot = Math.random() * Math.PI * 2 // test to offset the ball. 
				gltf.scene.rotation.y = ranRot; 
				scene.add(gltf.scene);
				render();
				clickNums ++
				//console.log(clickNums); 
				let flowerHead =  new THREE.Vector3(gltf.scene.position.x, gltf.scene.position.y + 65, gltf.scene.position.z) 
				beeDestintation.push(flowerHead)

			}, undefined, function (error) {
				console.error(error);
			});

			if (clickNums == 1) {
			loader.load('../models/bee-fast.glb', function (bee) {
			bee.scene.position.x = 0
			bee.scene.position.z = 3;
			bee.scene.position.y = 0;
			bee.scene.scale.set(8, 8, 8)
			bee.scene.name = "beeJoe"
			scene.add(bee.scene);
			mixerBee = new THREE.AnimationMixer(bee.scene)
			bee.animations.forEach((clipBee) => {
	    	  mixerBee.clipAction(clipBee).play()
			})
			animate(); 
			//render();
			}, undefined, function (error) {
			//console.error("grr");
			});
		}
		}

		//render();

	}

}

function onDocumentKeyDown(event) {

	switch (event.keyCode) {

		case 16: isShiftDown = true; break;

	}

}

function onDocumentKeyUp(event) {

	switch (event.keyCode) {

		case 16: isShiftDown = false; break;

	}

}

function render() {

	renderer.render(scene, camera);

}